@import "../../App/App.scss";

.mobile-menu {
  margin: 0 2vw 0 2vw;
  .static-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: $separator;
    padding-bottom: 4vw;
    margin: 4vw 0 2vw 0;
  }

  .moving-menu {
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;

    .social-links {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-bottom: 2vw;
      border-bottom: $separator;
      a {
        margin: 0.7rem 0;
        object {
          height: 2rem;
        }
      }
    }

    .section-links {
      min-height: 70vh;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      border-bottom: $separator;

      a {
        @extend .banner-h2;
        text-decoration: none;
        color: $font-color;
        font-size: 3.5rem;
      }
    }
  }

  .moving-menu-hidden {
    max-height: 0px;
  }
}
