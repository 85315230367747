@import "../../App/App.scss";

.nft-card {
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;
  border-radius: 10px;
  padding: 0.4rem;
  box-shadow: -4px 4px 6px 0 rgba(29, 29, 27, 0.2),
    0px 0px 0px 1px rgba(0, 0, 0, 1);
  transition: transform 0.5s ease-in-out;
  font-family: $text-font;

  .inner-body {
    padding: 1rem 1.5rem;
    grid-column: 1/1;
    grid-row: 1/1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .title {
      font-family: $secondary-font;
      font-size: 1.6rem;
    }

    .stats {
      margin-top: 0.7rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      span {
        font-size: 1.2rem;
      }
    }
  }

  .dashed {
    width: 100%;
    height: 100%;
    grid-column: 1/1;
    grid-row: 1/1;
    background-image: url("dashed.svg");
  }
  .card-logo {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    grid-column: 1/1;
    grid-row: 1/1;
    object {
      height: 4vw;
      padding-bottom: 1vw;
      padding-right: 1vw;
    }
  }
}

.gradient-moving {
  &:hover {
    background: linear-gradient(
      70deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 60%,
      rgba(254, 255, 235, 0.12) 75%,
      rgba(255, 255, 255, 0) 90%,
      rgba(255, 255, 255, 0) 100%
    );
    background-size: 200% 200%;
    animation: gradient-anim 0.3s linear;
  }
}

@keyframes gradient-anim {
  0% {
    background-position: 200% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
