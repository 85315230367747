@import "../../../App/App.scss";

.cards-menu {
  display: flex;
  flex-direction: row;
  margin: 4vw 0 2vw 0;
}

.card {
  $card-height: 25vw;
  @extend .primary-background;
  background-color: $primary-color;
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;
  height: $card-height;
  min-width: calc(#{$card-height} * 1.59);
  border-radius: 10px;
  padding: 0.7vw;
  box-shadow: -4px 4px 6px 0 rgba(29, 29, 27, 0.2),
    0px 0px 0px 1px rgba(0, 0, 0, 1);
  transition: transform 0.5s ease-in-out;
  font-family: $text-font;

  .inner-body {
    width: 90%;
    height: 85%;
    display: flex;
    grid-column: 1/1;
    grid-row: 1/1;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5vw;
    border-radius: 3%;
    filter: blur(2);
  }

  .dashed {
    width: 100%;
    height: 100%;
    grid-column: 1/1;
    grid-row: 1/1;
    background-image: url("dashed.svg");
  }

  .title-container {
    gap: 1vw;
    height: 6vw;
    display: flex;
    align-items: center;
  }

  h4 {
    @extend .banner-h2;
    display: inline-block;
    height: fit-content;
    width: fit-content;
    font-size: 5vw;
    font-weight: 100;
    line-height: 4.7vw;
  }

  .number {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 4.5vw;
    padding-top: 0.3vw;
    line-height: 5vw;
  }

  .number-text {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 4.5vw;
    line-height: 5vw;

    letter-spacing: -0.4vw;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .p-top {
    @extend .para-small;
  }
  .p-bottom {
    @extend .para-small;
    font-weight: 600;
    width: 65%;
  }
  .card-logo {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    grid-column: 1/1;
    grid-row: 1/1;
    object {
      height: 4vw;
      padding-bottom: 1vw;
      padding-right: 1vw;
    }
  }
}

@media (max-width: $mobile) {
  .card {
    border-radius: 5px;
    height: unset;

    .inner-body {
      width: 90%;
      height: 80%;
      padding: 3vw;
    }

    .p-top {
      font-size: 1.2rem;
    }
    .p-bottom {
      font-size: 1.2rem;
    }

    .title-container {
      padding-bottom: 0.5rem;
    }
    h4 {
      font-size: 2rem;
    }

    .number {
      font-size: 2rem;
    }

    .number-text {
      font-size: 2rem;
    }

    .card-logo {
      object {
        height: 2rem;
        padding-bottom: 1rem;
        padding-right: 1rem;
      }
    }
  }
}
