@import "../../App/App.scss";

.team-container {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  flex-wrap: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.team-container::-webkit-scrollbar {
  display: none;
}

.component-landing {
  @extend .primary-background;
  min-width: 1000px;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: $separator;
  z-index: 98;

  .component-text {
    display: flex;
    gap: 2vw;
    flex-direction: column;
    min-width: 500px;
    padding: 4em 4vw 4vh 4vw;
    border-bottom: $separator;

    .landing-title {
      @extend .cutout-text;
      width: fit-content;
      font-size: 20em;
    }

    .landing-img {
      height: 100%;
      object-fit: cover;
    }
  }
}

.component {
  @extend .primary-background;
  max-height: 100vh;
  display: flex;
  flex-direction: row;
  border-right: $separator;

  .bar {
    @extend .primary-background;
    min-width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5vh 0 5vh 0;
    gap: 2vw;
    align-items: center;
    border-right: $separator;
    transition: all 0.3s linear;

    .bar-title {
      @extend .banner-h2;
      font-size: 90px;
      writing-mode: vertical-rl;
      letter-spacing: 0.08em;
      line-height: 0.85em;
    }

    .bar-desc {
      @extend .para-big;
      font-size: 2rem;
      writing-mode: vertical-rl;
      text-transform: uppercase;
      text-shadow: 0px 1px 2px;
      width: fit-content;
      height: fit-content;
      text-align: right;
    }

    &:hover {
      background-color: $darkened-color;
      box-shadow: inset 0px 0px 8px 5px rgba(0, 0, 0, 0.42);
    }
  }
  .light {
    background-color: $lightened-color;
    box-shadow: inset 0px 0px 8px 2px rgba(0, 0, 0, 0.42);
  }
  .dark {
    background-color: $darkened-color;
    box-shadow: inset 0px 0px 8px 2px rgba(0, 0, 0, 0.42);
  }

  .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 0px;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0, 0.5, 1, 0.5);
  }

  .text-fixed {
    display: flex;
    flex-direction: column;
    gap: 2vw;
    justify-content: flex-start;
    align-items: center;
    min-width: 650px;
    padding: 1vw 2vw 0 2vw;
  }

  .sub-title {
    @extend .banner-h2;
    font-size: 12em;
  }
  .content-show {
    max-width: 800px;
    // min-width: 70vw;
  }
  .content-img {
    @extend .bordered-img;
    float: left;
    width: 50%;
    margin-right: 2vw;
  }

  .social-links {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-top: 1rem;
  }
}

@media (max-width: $small-desktop) {
  .team-container {
    margin-top: 8vw;
    flex-direction: column;
    border: 0;
    border-bottom: $separator;
  }

  .component-landing {
    max-width: unset;
    min-width: unset;
    width: 100%;

    .component-text {
      justify-content: center;
      align-items: center;
      padding: 4em 6vw 4em 6vw;
      min-width: unset;
    }
  }

  .component {
    max-height: none;
    flex-direction: column;

    .bar {
      flex-direction: row;
      padding: 4vw 6vw 4vw 6vw;
      border: 0;
      border-bottom: $separator;
      .bar-title {
        writing-mode: unset;
      }

      .bar-desc {
        writing-mode: unset;
      }
    }

    .content-container {
      max-width: none;
      max-height: 0;
      border: 0;
      border-bottom: $separator;

      .text-fixed {
        justify-content: center;
        align-items: center;
        padding: 4vw;
        min-width: unset;
        .content-img {
          width: 50%;
        }
      }
    }

    .content-show {
      max-width: none;
      max-height: 900px;
    }
  }
}

@media (max-width: $mobile) {
  .component-landing {
    .component-text {
      .landing-title {
        font-size: 85vw;
      }
    }
  }

  .component {
    .bar {
      .bar-title {
        font-size: 3.5rem;
      }
      .bar-desc {
        width: 40%;
        font-size: 1.5rem;
      }
    }

    .content-container {
      .text-fixed {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        .sub-title {
          font-size: 8em;
        }
      }
    }
  }
}
