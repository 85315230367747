@import "../../App/App.scss";

.three-section {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  height: 20%;
  margin: 4vw 2vw 4vw 2vw;
  h4 {
    @extend .header-small;
  }
  p {
    @extend .para-small;
    text-align: center;
  }

  .column {
    display: flex;
    gap: 2vw;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
    padding: 0 2vw 0 2vw;
  }

  .col-1 {
    border-right: $separator;
  }

  .col-2 {
    .link-to-team {
      display: flex;
      align-items: center;
      gap: 1vw;
      text-decoration: none;
      color: $font-color;

      .arrow {
        height: 4vw;
        transition: transform 0.5s cubic-bezier(0.89, -0.36, 0.22, 1.39);
      }

      .title {
        transition: transform 0.5s cubic-bezier(0.89, -0.36, 0.22, 1.39);
      }
      &:hover {
        .arrow {
          transform: translateX(2vw) rotateZ(180deg);
        }
      }
    }
    .show-active {
      .arrow {
        transform: rotateZ(180deg);
      }

      .title {
        transform: translateX(-2vw);
      }
    }
  }

  .col-3 {
    border-left: $separator;

    padding-right: 0;

    .side-img {
      @extend .bordered-img;
      object-fit: cover;
      height: 14vw;
    }
  }
}

@media (max-width: $mobile) {
  .three-section {
    grid-template-columns: 1fr;
    gap: 4vw;

    .col-1,
    .col-2 {
      border: 0;
      p {
        font-size: 1.5rem;
      }

      h4,
      a {
        font-size: 3rem;
      }
    }

    .col-1 {
      grid-row: 3;
    }

    .col-2 {
      border: 0;
      border-bottom: $separator;
      padding-top: 2vw;
      padding-bottom: 4vw;

      .link-to-team {
        .arrow {
          height: 5vw;
        }

        &:hover {
          .arrow {
            transform: translateY(-20px) rotateZ(90deg);
          }
        }
      }

      .show-active {
        .arrow {
          transform: rotateZ(90deg);
        }

        .title {
          transform: translateX(-2vw);
        }
      }
    }

    .col-3 {
      padding: 0;
      width: 100%;
      grid-row: 1;
      grid-column: span 2;
      border: 0;
      // padding-bottom: 4vw;

      .side-img {
        width: 100%;
        height: unset;
      }
    }
  }
}
