@import "../../App/App.scss";

$segment-color: rgb(26, 28, 26);

.roadmap {
  display: flex;
  flex-direction: column;
  margin: 0 2vw 2vw 2vw;
  gap: 4vw;
  padding-top: 4vw;
  border-top: $separator;

  .roadmap-header {
    @extend .banner-h2;
    font-size: 35vw;
    text-align: left;
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;

    span {
      cursor: pointer;
    }
    .arrow {
      height: 12vw;
      object-fit: contain;
      transition: all 0.5s cubic-bezier(0.89, -0.36, 0.22, 1.39);
    }

    &:hover {
      .arrow {
        transform: translateX(-30%);
      }
    }
  }

  .roadmap-subsection {
    display: flex;
    gap: 2vw;

    .roadmap-img {
      @extend .bordered-img;
      width: 50%;
    }

    .roadmap-para {
      @extend .para-big;
      border-left: $separator;
      padding-left: 2vw;
    }
  }
}

.timeline {
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;

  p {
    font-size: 2vw;
    line-height: 1em;
    padding-top: 0.08em;
  }
}

.segment {
  display: flex;
  gap: 1vw;
  align-items: center;
  width: 100%;
  min-height: 4.7vw;

  .graph {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 2vw;
      min-height: 2vw;
      border: 0.3vw solid $segment-color;
      border-radius: 50%;
      transition: box-shadow 0.2s ease-in-out;

      .dot {
        width: 1.4vw;
        height: 1.4vw;
        background-color: rgb(27, 27, 27);
        border-radius: 50%;
        display: inline-block;
        opacity: 100;
        transition: opacity 0.5s linear;
      }
      .hidden-dot {
        opacity: 0;
      }
    }

    .line {
      width: 0.2vw;
      height: 100%;
      background-color: $segment-color;
    }
    .segment-ending {
      min-width: 2vw;
      min-height: 1vw;
      border-top-left-radius: 2vw;
      border-top-right-radius: 2vw;
      border: 0.3vw solid $segment-color;
      border-bottom: 0;
    }
  }

  .header {
    @extend .para-big;
    align-self: flex-start;
  }

  .subtext {
    @extend .para-small;
    max-height: 1000px;
    transform: scaleY(1);
    transform-origin: top;
    transition: max-height 1s linear;
    align-self: flex-start;
    padding-bottom: 2vw;
    ul {
      list-style-position: inside;
      font-size: 1.8vw;
    }
  }
}

@media (max-width: $mobile) {
  .roadmap {
    flex-direction: column;
    padding-bottom: 4vw;

    .roadmap-subsection {
      flex-direction: column;

      .roadmap-img {
        width: 100%;
      }

      .roadmap-para {
        border-left: none;
        padding-left: 0;
        padding-top: 1rem;
      }
    }
  }

  .timeline {
    width: 100%;
    .segment {
      display: grid;
      grid-template-columns: 10% 90%;
      box-sizing: border-box;
      transition: all 1s ease-out;
      min-height: 3.5rem;

      p {
        padding-top: 0.1rem;
        font-size: 1.6rem;
      }

      .graph {
        grid-row: span 2;
        .circle {
          min-width: 1.5rem;
          min-height: 1.5rem;
          border: 0.2rem solid $segment-color;

          .dot {
            min-width: 1rem;
            min-height: 1rem;
          }
        }
        .segment-ending {
          min-width: 1.5rem;
          min-height: 0.75rem;
          border-top-left-radius: 1.5rem;
          border-top-right-radius: 1.5rem;
          border: 0.2rem solid $segment-color;
          border-bottom: 0;
        }

        .line {
          height: 100%;
          width: 0.1rem;
        }
      }

      .subtext {
        padding-bottom: 1rem;
        ul {
          font-size: 1.4rem;
        }
      }
    }
  }
}
