@import "../../App/App.scss";

.couter-container {
  display: flex;
  justify-content: space-between;
  margin: 0 2vw 0 2vw;
  border: $separator;
  border-left: 0;
  border-right: 0;
  gap: 4vw;
}

.single-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 15vw;
  gap: 0.7vw;

  .text-box {
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.3vw;
    width: fit-content;
  }

  .upper-text {
    @extend .para-small;
    padding: 0;
    margin: 0;
    display: block;
    box-sizing: border-box;
    width: fit-content !important;
    text-align: center;
    line-height: 2vw;
  }

  .counter-header {
    @extend .header-small;
    white-space: nowrap;
    width: fit-content;
  }

  .number {
    font-family: $secondary-font;
    font-size: 5vw;
    flex-grow: 2;
    width: fit-content;
    height: fit-content;
  }
}

@media (max-width: $mobile) {
  .couter-container {
    display: grid;
    gap: 4vw;
    grid-template-columns: 1fr 1fr;
    padding: 6vw 0 4vw 0;
  }

  .single-counter {
    flex-direction: column;
    gap: 4vw;

    .text-box {
      flex-direction: column;
      justify-content: flex-end;
      flex-grow: 3;
    }

    .upper-text {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }

    .counter-header {
      font-size: 3rem;
    }

    .number {
      font-size: 3rem;
      line-height: 3rem;
    }
  }
}
