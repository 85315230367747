@import "../../App/App.scss";

.collection-container {
  margin: 4vw 2vw 0 2vw;
  display: grid;
  gap: 3.7vw;
  row-gap: 4vw;
  grid-template-columns: 53vw auto;
  span {
    overflow: hidden;
    font-family: $general-font;
    font-weight: 100;
    font-size: $h1-height;
    line-height: 0.8em;
  }
  .grid-span-2 {
    grid-column: span 2;
  }
  .sub-grid-1 {
    display: grid;
    gap: 4vw;
    grid-template-columns: 20vw 30vw;
    overflow: hidden;

    img {
      @extend .bordered-img;
      height: calc(#{$h1-height} - 8.5vw);
      object-fit: cover;
      object-position: 62% 0;
    }

    .bottom-text {
      line-height: 0.85em;
    }
  }

  .big-image {
    @extend .bordered-img;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 61% 0;
    transform: scaleX(-1);
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4vw;
  }

  .bottom-image {
    @extend .bordered-img;
    width: 100%;
    object-fit: cover;
    object-position: 30% 100%;
    cursor: pointer;
  }

  .lower-text {
    display: flex;
    flex-direction: column;
    gap: 2vw;

    .collection-button {
      display: flex;
      gap: 0.5vw;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;
      .button-text {
        font-size: 17vw;
        line-height: 0.9em;
        color: $font-color;
      }

      .text-spread {
        letter-spacing: 3.2vw;
      }

      .arrow {
        height: 5vw;
        transform: rotateZ(180deg);
        transition: transform 0.5s cubic-bezier(0.89, -0.36, 0.22, 1.39);
      }
      .button-soon {
        display: none;
      }
      &:hover {
        .arrow {
          transform: rotateZ(180deg) translateX(-150%);
        }

        .button-ranking {
          display: none;
        }

        .button-soon {
          display: block;
        }
      }
    }
  }
}
