@import "../../App/App.scss";

$menu-height: 8em;

.navigation {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.upper-navbar {
  @extend .primary-background;
  display: flex;
  padding: 0 2vw 0 2vw;
  box-sizing: border-box;
  width: 100%;
  min-height: $menu-height;
  justify-content: space-between;
  align-items: center;
  border-bottom: $separator;
  z-index: 99;
  background-color: $primary-color;

  .last {
    display: flex;
    gap: 4vw;
    align-items: center;
    justify-content: center;

    a {
      transition: transform 0.2s ease-out;
    }
  }
}

.lower-navbar {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-height: 0px;
  z-index: 98;
  transition: max-height 0.5s ease-in-out;
  border-bottom: $separator;
  overflow: hidden;

  .inner-body {
    min-height: $menu-height;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;
  }

  .dummy-container {
    width: 30px;
    height: 30px;
    margin-right: 2vw;
  }
}

.menu-open {
  max-height: $menu-height;
}

.nav-link {
  text-decoration: none;
  color: black;
  font-family: $secondary-font;
  font-size: 2.4vw;
  font-weight: 200;
}

.nav-link-active {
  text-decoration: underline;
  text-underline-offset: 0.2em;
}

.sticky {
  @extend .primary-background;
  position: fixed;
  top: 0;
  background-color: $primary-color;
  z-index: 100;
}

.nav-padding {
  padding-top: $menu-height;
}

//MediaQueries

@media (min-width: 1300px) {
  .nav-link {
    font-size: 30px;
  }
}
