@import "../../App/App.scss";

.home-link {
  text-decoration: none;
}

.home-button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;

  .arrow {
    width: 70px;
    object-fit: contain;
    transition: all 0.5s cubic-bezier(0.89, -0.36, 0.22, 1.39);
  }

  .home-text {
    @extend .banner-h2;
    text-decoration: none !important;
    font-size: 4rem;
    writing-mode: vertical-lr;
    transition: all 0.5s cubic-bezier(0.89, -0.36, 0.22, 1.39);
    cursor: pointer;
  }

  &:hover {
    .arrow {
      transform: translateX(-200%);
    }

    .home-text {
      transform: rotateZ(-90deg);
    }
  }
}

.home-button-horizontal {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  gap: 1em;
  align-items: center;

  .arrow {
    width: 70px;
    object-fit: contain;
    transition: all 0.5s cubic-bezier(0.89, -0.36, 0.22, 1.39);
  }

  .home-text {
    @extend .banner-h2;
    text-decoration: none !important;
    font-size: 4rem;
    transition: all 0.5s cubic-bezier(0.89, -0.36, 0.22, 1.39);
    cursor: pointer;
  }

  &:hover {
    .arrow {
      transform: translateX(-300%);
      opacity: 0;
    }
    .home-text {
      transform: translateX(20%);
    }
  }
}

@media (max-width: $small-desktop) {
  .home-button {
    width: auto;
    height: auto;
    padding-left: 4vw;
    flex-direction: row;

    .arrow {
      width: auto;
      height: 2.5rem;
    }

    .home-text {
      writing-mode: initial;
      font-size: 4rem;
    }

    &:hover {
      .home-text {
        transform: translateX(20%);
      }
    }
  }
}

@media (max-width: $mobile) {
  .home-button .home-button-horizontal {
    .arrow {
      height: 1.5rem;
    }
    .home-text {
      font-size: 3rem;
    }
  }
}
