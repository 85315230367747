@import "../../App/App.scss";
$size-small: 1.5rem;
$size-medium: 3rem;
$size-large: 4rem;

.small {
  object {
    height: $size-small;
  }
}

.medium {
  object {
    height: $size-medium;
  }
}

.large {
  object {
    height: $size-large;
  }
}

.nav-logo {
  object {
    height: 3rem;
  }
}

.footer-logo {
  object {
    height: 2.5rem;
  }
}

@media (max-width: $mobile) {
  .nav-logo {
    object {
      height: 2.5rem;
    }
  }

  .footer-logo {
    object {
      height: 2rem;
    }
  }
}
