@import "../../App/App.scss";

.ranking-header {
  width: 100%;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: $separator;
  padding: 1rem 0;

  .btn-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .search {
    background-color: transparent;
    flex-grow: 1;
    border: 0;
    border-bottom: $separator;
    font-family: $secondary-font;
    font-size: 2rem;
    width: 50%;
    border-radius: 3px;
    padding: 0.4rem;
    padding-left: 0.7rem;
    transition: background-color 0.3s linear;

    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }

  .sort {
    display: flex;
    gap: 1rem;
    span {
      @extend .banner-h2;
      font-size: 4rem;
      white-space: nowrap;
      padding-right: 0.7rem;
    }

    button {
      display: flex;
      align-items: center;
      @extend .banner-h2;
      font-size: 3.5rem;
      background-color: transparent;
      border: 0;
      transition: all 0.3s cubic-bezier(0.89, -0.36, 0.22, 1.39);
      cursor: pointer;

      .arrow {
        height: 1.25rem;
        transition: all 0.3s cubic-bezier(0.89, -0.36, 0.22, 1.39);
      }

      .arrow-active-ascending {
        transform: rotate(-90deg);
      }

      .arrow-active-descending {
        transform: rotate(90deg);
      }

      &:hover {
        transform: scale(1.15);
      }
    }
  }
}

@media (max-width: 650px) {
  .ranking-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    .btn-logo {
      width: 100%;
    }

    .search {
      flex-grow: 1;
      padding-top: 1rem;
      width: 100%;
      border-top: $separator;
    }
  }
}
