@import "../../App/App.scss";

.mint-section {
  @extend .custom-section;
  flex-direction: column;
}

.mint-header {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 4vw;
  height: 30vw;
}

.mint-hero {
  @extend .cutout-text;
  margin: 0;
  width: 80%;
  font-size: 35vw;
}

.button-container {
  width: 20%;
}

.mint-navlink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vw;
  text-decoration: none;
  color: $font-color;
  cursor: pointer;

  .arrow {
    height: 8vw;
    transition: transform 0.5s cubic-bezier(0.89, -0.36, 0.22, 1.39);
  }

  span {
    @extend .banner-h2;
    font-size: 10vw;
    line-height: 9vw;
    cursor: pointer;
    transform: rotateZ(90deg);
    transition: transform 0.5s cubic-bezier(0.89, -0.36, 0.22, 1.39);
  }

  .span-hidden {
    display: none;
    transform: scale(0);
  }
}

.mint-navlink-hover {
  &:hover {
    .arrow {
      transform: rotateZ(180deg) translateX(-150%);
    }

    .span-hidden {
      display: block;
      transform: scale(1);
    }

    .span-shown {
      display: none;
    }
  }
}

.animate-link {
  .arrow {
    transform: rotateZ(180deg);
  }
  span {
    transform: rotateZ(0deg);
  }
}

// .mint-button {
//   display: inline-block;
//   width: 100%;
//   height: 100%;
//   color: rgb(53, 53, 53);
//   font-family: $general-font;
//   font-size: 10vw;
//   font-weight: 100;
//   line-height: 11vw;
//   border: 5px solid rgb(53, 53, 53);
//   background-color: transparent;
//   padding: 0;
//   text-transform: uppercase;
//   border-radius: 10px;
//   mask-image: url("../../images/grunge.png");
//   mask-size: 944px 604px;
//   mix-blend-mode: multiply;
//   transition: transform 0.5s ease-in-out;
// }

.mint-button:hover {
  transform: translate(-5px, 0);
}
