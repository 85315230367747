@import url("https://fonts.googleapis.com/css2?family=Spectral:wght@200;300;400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Radley:ital@1&display=swap");

@font-face {
  font-family: "AlycidonCondensed";
  src: url("../font/alycidon/alycidon-condensed-webfont.woff2") format("woff2"),
    url("../font/alycidon/alycidon-condensed-webfont.woff") format("woff");
  font-weight: 100;
}
//^ imports ^
//v variables v
$primary-color: #b3a89a;
$lightened-color: #b3a89a88;
$darkened-color: #9e9487;
$font-color: #1c1c1a;
// $background-url: url("./fibers-paper.png");
// $background-url: url("../images/handmade-paper.png");
$background-url: url("cream-paper.png");
$general-font: "AlycidonCondensed", serif;
$secondary-font: "Spectral", serif;
$text-font: "Spectral", serif;
$numbers-font: "Radley", serif;
$h1-height: 42vw;
$h2-height: 27vw;
$h3-height: 10vw;
$p-height: 2.5vw;
$separator: 1px solid
  rgba(
    $color: #000000,
    $alpha: 0.2,
  );

//Declarations

.primary-background {
  background-image: $background-url;
  background-color: $primary-color;
}

body {
  @extend .primary-background;
}

.custom-section {
  display: flex;
  margin: 2vw;
  margin-top: 4vw;
}

a {
  cursor: pointer;
}

object {
  filter: invert(6%) sepia(10%) saturate(496%) hue-rotate(22deg) brightness(91%)
    contrast(88%);
  pointer-events: none;
}

.cutout-text {
  background-color: $font-color;
  font-family: $general-font;
  color: white;
  font-size: $h1-height;
  font-weight: 500;
  text-align: center;
  line-height: 0.8em;
  padding-top: 0.05em;
  padding-bottom: 0.03em;
  padding-left: 0.04em;
  mix-blend-mode: multiply;
  cursor: default;
}

.bordered-img {
  width: 100%;
  display: block;
  border: 1px solid $font-color;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 1);
  box-sizing: border-box;
}

.App {
  @extend .primary-background;
  background-color: $primary-color;
  overflow-x: hidden;
  width: 100%;
}

.banner-h2 {
  font-family: $general-font;
  color: $font-color;
  font-weight: 100;
  font-size: $h2-height;
  text-align: center;
  padding: 0;
  line-height: 0.8em;
  cursor: default;
}

.para-big {
  font-family: $secondary-font;
  letter-spacing: -0.07em;
  word-spacing: 0.2em;
  font-size: $p-height;
  line-height: 1.05em;
  cursor: default;
  line-break: loose;
}

.para-small {
  font-family: $secondary-font;
  letter-spacing: -0.07em;
  word-spacing: 0.2em;
  font-size: 2vw;
  cursor: default;
}

.header-small {
  font-family: $general-font;
  font-size: 5vw;
  font-weight: 100;
}

//Breakpoints
$mobile: 480px;
$tablet: 768px;
$small-desktop: 1200px;

//PaperPara classes

$para-size-mobile: 1.6rem;
$para-size-team: 2.5em;

.paper-para-team {
  span {
    font-size: calc(#{$para-size-team * 2.7});
    margin-right: 0.8rem;
  }
  p {
    font-size: $para-size-team;
    max-width: 100%;
  }
}

@media (max-width: $mobile) {
  .paper-para-mobile {
    span {
      font-size: calc(#{$para-size-mobile * 3.3});
      padding-left: 5px;
      padding-right: 3px;
      margin-right: 0.4rem;
    }
    p {
      font-size: $para-size-mobile;
      max-width: 100%;
    }
  }
}
