@import "../../App/App.scss";

.footer {
  display: flex;
  flex-direction: column;
  border-top: $separator;
  margin: 0 2vw 0 2vw;

  .upper-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: $separator;
    padding: 2vw 0 2vw 0;

    .links {
      display: flex;
      gap: 2vw;
    }
  }

  .lower-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5vw 0 0.5vw 0;

    .info {
      display: flex;
      gap: 1vw;

      a,
      span {
        @extend .para-small;
        text-decoration: none;
        color: black;
        font-weight: 100;
      }
      a {
        transition: transform 0.5s ease-in-out;
        cursor: pointer;
      }

      @media (min-width: 1300px) {
        a,
        span {
          font-size: 25px;
        }
      }
    }
  }
}

@media (max-width: $mobile) {
  .footer {
    .upper-row {
      flex-direction: row;
      gap: 4vw;
      padding: 2vw 0 2vw 0;
      .links {
        gap: 6vw;
        .icon-wrapper {
          font-size: 2rem;
        }
      }
    }

    .lower-row {
      justify-content: center;

      span,
      a {
        font-size: 0.7rem;
      }
    }
  }

  .footer-team-page {
    border: 0;
  }
}

@media (min-width: $small-desktop) {
  .footer-team-page {
    flex-direction: row;
    border: 0;
    margin: 0;

    .upper-row {
      flex-direction: column-reverse;
      border: 0;
      border-right: $separator;
      align-items: center;
      justify-content: space-around;

      .links {
        padding: 2vw;
        flex-direction: column;
        align-items: center;

        a {
          writing-mode: vertical-rl;
        }
      }

      .logo {
        transform: rotateZ(90deg);
      }
    }

    .lower-row {
      .info {
        flex-direction: column;

        a,
        span {
          writing-mode: vertical-rl;
        }
      }
    }
  }
}
