@import "../App/App.scss";
.social-link {
  height: 1.7rem;
  object {
    transition: transform 0.3s cubic-bezier(0.89, -0.36, 0.22, 1.39);
    height: 100%;
  }

  &:hover {
    object {
      transform: scale(1.2);
    }
  }
}

.social-link-text {
  font-family: $general-font;
  font-size: 3rem;
  letter-spacing: normal;
  font-weight: 100;
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.89, -0.36, 0.22, 1.39);
  text-decoration: none;
  color: $font-color;
  &:hover {
    transform: scale(1.2);
  }
}

@media (max-width: $mobile) {
  .social-link {
    height: 2rem;
  }

  .social-link-text {
    font-size: 2.5rem;
  }
}
