@import "../../App/App.scss";

.rarity-section {
  margin: 4vw 2vw 4vw 2vw;
  overflow: hidden;
}

.slider-container {
  width: 100%;
  height: 100%;
  padding: 2vw 0 2vw 0;
  border-top: $separator;
  border-bottom: $separator;
}

.component-wrapper {
  display: flex;
  align-items: center;
  gap: 1vw;
  animation: moving 15s linear infinite;

  &:hover {
    animation-play-state: paused;
  }
}

.slider {
  width: 100%;
  height: 100%;
  display: flex;
  // gap: 4vw;
}

.slider-component {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-right: 4vw;
  width: 100%;
  flex-wrap: nowrap;
  transition: transform 1s linear;
  .title {
    display: block;
    @extend .banner-h2;
    font-size: 5vw;
    letter-spacing: 0.1em;
  }
  .description {
    display: block;
    padding-right: 1.5rem;
    span {
      @extend .para-small;
      font-size: 5.8vw;
      font-weight: 300;
      height: fit-content;
      line-height: 0.5em;
    }
  }
}

@keyframes moving {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(-50.2%);
  }
}

@media (max-width: $mobile) {
  .slider-container {
    padding: 6vw 0 6vw 0;
  }
  .slider-component {
    .title {
      font-size: 3rem;
    }
    .description {
      span {
        font-size: 3.2rem;
        line-height: 1rem;
      }
    }
  }
}
