@import "../../App/App.scss";

.nav-side {
  @extend .primary-background;
  height: 100vh;
  width: 160px;
  border-right: $separator;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .logo-wrapper {
    transform: rotateZ(90deg);
  }
}

@media (max-width: $small-desktop) {
  .nav-side {
    height: 70px;
    width: 100%;
    margin: auto;
    position: fixed;
    top: 0;

    flex-direction: row;
    justify-content: space-between;
    z-index: 100;
    padding: 2vw 0 2vw 0;
    border: 0;
    border-bottom: $separator;

    .logo-wrapper {
      padding-right: 4vw;
      transform: rotateZ(0deg);
    }
  }
}
