@import "../../App/App.scss";

$button-size: 32px;
$line-gap: 8px;
$line-width: 2px;

.menu-button {
  width: $button-size;
  height: $button-size;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $line-gap;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    .move-right {
      transform: translate(5px);
    }
    .move-left {
      transform: translate(-5px);
    }
  }
}
.hidden {
  display: none;
}
.button-line {
  width: $button-size;
  height: $line-width;
  background-color: $font-color;
  transition: transform 0.3s ease-in-out;
}

.line-hidden {
  display: none;
}

//Animations

@keyframes cross-top-in {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, $line-gap);
  }
  100% {
    transform: rotate(45deg) translate(-1px, $line-gap);
  }
}

@keyframes cross-top-out {
  0% {
    transform: rotate(45deg) translate(-1px, $line-gap);
  }
  50% {
    transform: translate(0, $line-gap);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes cross-bottom-in {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -$line-gap);
  }
  100% {
    transform: rotate(-45deg) translate(-1px, -$line-gap);
  }
}

@keyframes cross-bottom-out {
  0% {
    transform: rotate(-45deg) translate(-1px, -$line-gap);
  }
  50% {
    transform: translate(0, -$line-gap);
  }
  100% {
    transform: translate(0, 0);
  }
}

.cross-top-animate-forward {
  animation: cross-top-in 0.5s ease-in-out normal forwards;
}

.cross-top-animate-reverse {
  animation: cross-top-out 0.5s ease-in-out normal forwards;
}

.cross-bottom-animate-forward {
  animation: cross-bottom-in 0.5s ease-in-out normal forwards;
}

.cross-bottom-animate-reverse {
  animation: cross-bottom-out 0.5s ease-in-out normal forwards;
}
