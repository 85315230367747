@import "../../App/App.scss";

$column-gap: 2vw;

.club-section {
  @extend .custom-section;
  border-bottom: $separator;
  padding-bottom: 4vw;
}

.left {
  display: flex;
  flex-direction: column;
  gap: 2vw;
  width: 33%;
  border-right: $separator;
  padding-right: $column-gap;
}

.right {
  display: flex;
  flex-direction: column;
  gap: 2vw;
  width: 66%;
  padding-left: $column-gap;
}

.header-image-club {
  display: flex;
  flex-direction: column;
  gap: 2vw;
}

.club-header {
  @extend .banner-h2;
  text-align: left;
}

.club-para-1 {
  @extend .para-big;
}

.club-para-2 {
  font-family: $general-font;
  font-size: $h3-height;
  font-weight: 400;
  line-height: 0.9em;
  word-spacing: 0.5vw;
  cursor: default;
}

.club-img-1 {
  @extend .bordered-img;
  object-fit: cover;
}

.club-img-2 {
  @extend .bordered-img;
  margin-top: 0;
}

@media (max-width: $mobile) {
  .club-section {
    flex-direction: column;
    gap: 4vw;
  }

  .left {
    width: 100%;
    display: block;
    border: 0;
    border-bottom: $separator;
    padding-bottom: 4vw;

    .header-image-club {
      float: left;
      width: 33%;
      padding-right: 2vw;
    }

    .club-header {
      text-align: left;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;

    .club-para-2 {
      width: 100%;
      text-align: center;
    }
  }
}
