@import "../../App/App.scss";

.floating {
  @extend .cutout-text;
  font-size: $h3-height;
  font-weight: 500;
  line-height: 0.9em;
  padding: 0.5vw;
  margin: 0 1vw 0 0;
  float: left;
}

@media (max-width: $mobile) {
  .floating {
    margin-right: 1.5vw;
  }
}
